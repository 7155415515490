<template>
  <div>
    <q-form ref="editForm">
      <c-card title="수급업체 상세" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable && parentVendorCd" label="접속ID 변경" icon="verified_user" @btnClicked="changePortalId" />
            <c-btn
              v-if="editable && parentVendorCd"
              :url="resetUrl"
              :isSubmit="isReset"
              :param="data"
              mappingType="PUT"
              label="비밀번호초기화"
              icon="password"
              @beforeAction="resetPwd"
              @btnCallback="resetPwdCallback" />
            <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addVendor" />
            <c-btn
              v-if="editable"
              :disabled="!saveable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-4">
            <c-text
              :required="true"
              :editable="editable && dataeditable"
              label="업체"
              name="vendorName"
              v-model="data.vendorName">
            </c-text>
          </div>
          <div class="col-4">
            <c-text
              :required="true"
              :editable="editable && !updateMode && dataeditable"
              label="업체코드"
              name="vendorCd"
              v-model="data.vendorCd">
            </c-text>
          </div>
          <div class="col-4">
            <c-text
              :required="true"
              :editable="editable && dataeditable"
              label="사업자번호"
              name="bizNo"
              mask="###-##-#####"
              v-model="data.bizNo"
              @dataChange="(value) => { data.portalId = $_.replace(value, /-/g, '') }">
            </c-text>
          </div>
          <div class="col-4">
            <c-select
              codeGroupCd="VENDOR_CLASS_CD"
              :editable="editable && dataeditable"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="vendorClassCd"
              label="업체분류"
              v-model="data.vendorClassCd"
            ></c-select>
          </div>
          <div class="col-4">
            <c-select
              codeGroupCd="VENDOR_TYPE_CD"
              :editable="editable && dataeditable"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="vendorTypeCd"
              label="업체유형"
              v-model="data.vendorTypeCd"
            ></c-select>
          </div>
          <div class="col-4">
            <c-text
              :editable="editable && dataeditable"
              label="대표자명"
              name="representName"
              v-model="data.representName">
            </c-text>
          </div>
          <div class="col-4">
            <c-text
              :editable="editable && dataeditable"
              :phoneNumberCheck="true"
              label="대표전화번호"
              name="phoneNo"
              v-model="data.phoneNo">
            </c-text>
          </div>
          <div class="col-4">
            <c-text
              :editable="editable && dataeditable"
              label="대표이메일"
              name="email"
              v-model="data.email">
            </c-text>
          </div>
          <div class="col-4">
            <c-text
              :editable="editable && dataeditable"
              label="담당자명"
              name="chargeName"
              v-model="data.chargeName">
            </c-text>
          </div>
          <div class="col-8">
            <c-text
              :editable="editable && dataeditable"
              label="본사주소"
              name="address"
              v-model="data.address">
            </c-text>
          </div>
          <div class="col-4">
            <c-checkbox
              :editable="editable && dataeditable"
              :isFlag="true"
              label="사용여부"
              name="useFlag"
              v-model="data.useFlag"
            />
          </div>
          <div class="col-12">
            <c-multi-select
              :required="true"
              :editable="editable && dataeditable"
              :isArray="false"
              codeGroupCd="PLANT_CD"
              itemText="codeName"
              itemValue="code"
              label="관련사업장"
              name="plantCds"
              v-model="data.plantCds">
            </c-multi-select>
          </div>
        </template>
      </c-card>
      <c-card title="수급업체 포털 접속여부" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-6">
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="수급업체 포털 접속여부"
              name="portalConnFlag"
              v-model="data.portalConnFlag"
            />
          </div>
          <div class="col-6">
            <c-text
              readonly
              :editable="editable"
              label="접속 ID"
              name="portalId"
              v-model="data.portalId">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-detail',
  props: {
    parentVendorCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        chargeDeptCd: '',  // 담당부서 코드
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        portalConnFlag: 'Y',  // 협력업체포탈 접속 여부
        portalId: '',  // 포탈 ID
        plantCds: '',  // 관련사업장
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendorUser: '',
      },
      resetUrl: '',
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      isReset: false,
      saveUrl: transactionConfig.mdm.cim.vendor.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedVendorCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    parentVendorCd() {
      if (this.parentVendorCd) {
        this.rowClick({ vendorCd: this.parentVendorCd })
      } else {
        this.addVendor();
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.detailUrl = selectConfig.mdm.cim.vendor.get.url;
      this.checkUrl = selectConfig.mdm.cim.vendor.check.url;
      this.insertUrl = transactionConfig.mdm.cim.vendor.insert.url;
      this.updateUrl = transactionConfig.mdm.cim.vendor.update.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.delete.url;
      this.resetUrl = transactionConfig.mdm.cim.vendor.reset.url;
    },
    changePortalId() {
      this.popupOptions.title = '협력업체 Portal 아이디 변경';
      this.popupOptions.param = {
        vendorCd: this.parentVendorCd,
        loginId: this.data.portalId,
      }
      this.popupOptions.target = () => import(`${'./portalIdChange.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(_portalId) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_portalId.portalId !== undefined) {
        this.data.portalId = _portalId.portalId.data;
      }
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedVendorCd = row.vendorCd;
      this.$http.url = this.$format(this.detailUrl, this.selectedVendorCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addVendor() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        chargeDeptCd: '',  // 담당부서 코드
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        portalConnFlag: 'Y',  // 협력업체포탈 접속 여부
        portalId: '',  // 포탈 ID
        plantCds: '',  // 관련사업장
        useFlag: 'Y',  // 사용여부
      };
      this.$emit('isNew');
    },
    reset() {
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        vendorCd: '',  // 업체코드
        vendorName: '',  // 업체명
        vendorClassCd: null,  // 업체분류 코드
        vendorTypeCd: null,  // 업체유형 코드
        address: '',  // 본사주소
        bizNo: '',  // 사업자번호
        chargeDeptCd: '',  // 담당부서 코드
        phoneNo: '',  // 대표 전화번호
        email: '',  // 대표 이메일
        chargeName: '',  // 담당자명
        representName: '',  // 대표자명
        portalConnFlag: 'Y',  // 협력업체포탈 접속 여부
        portalId: '',  // 포탈 ID
        plantCds: '',  // 관련사업장
        useFlag: 'Y',  // 사용여부
      };
      this.$emit('isNew');
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.vendorCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId
                  this.data.chgUserId = this.$store.getters.user.userId
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message:
                '동일한 업체코드가 존재합니다.', 
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    resetPwd() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '비밀번호를 초기화 하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.chgUserId = this.$store.getters.user.userId
          this.isReset = !this.isReset;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.vendorCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('searchDetail');
      if (this.saveType == 'POST') {
        this.reset();
        this.selectedVendorCd = '';
      }
    },
    resetPwdCallback() {
      window.getApp.$emit('ALERT', {
        title: '안내',
        message:
        '초기비밀번호(환경설정값)로 초기화 되었습니다.',
        type: 'success', // success / info / warning / error
      });
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('searchDetail');
      this.reset();
      this.selectedVendorCd = '';
    },
  }
};
</script>
